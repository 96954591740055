
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  ref,
  watch,
} from "vue";
import {
  ApiBase,
  ApiFulfillmentOrders,
  ApiShipments,
  ApiStock,
} from "@/core/api";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import mixin from "@/mixins";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import { TaggingItem } from "@/core/directive/interface/common";
import { ProductItem } from "@/core/directive/interface/order";
import { CommonFormType, CommonHtmlType } from "@/core/directive/type/common";
import {
  ShipmentAddStockOutListFilterOption,
  AddStockOutListSubmitData,
} from "@/core/directive/interface/shipment";
import {
  formatDate,
  formatDateTime,
  modalShowListener,
  showModal,
} from "@/core/directive/function/common";
import { ElTable } from "element-plus";
import { FulfillmentSource } from "@/core/directive/type/fulfillmentOrder";
import { MenuComponent } from "@/assets/ts/components";
import { User } from "@/store/modules/AuthModule";
import store from "@/store";
import accounting from "accounting-js";

export default defineComponent({
  name: "add-pre-stock-out-list",
  components: {
    ProductInfo,
    SkuInfo,
  },
  props: {
    fulfillment_order_id: {
      type: Number,
      required: false,
      default: 0,
    },
    fulfillment_order_number: {
      type: String,
      required: false,
      default: "",
    },
    fulfillment_order_type: {
      type: Number,
      required: false,
      default: 0,
    },
    shipment_invoice_id: {
      type: Number,
      required: false,
      default: 0,
    },
    id: { type: Number, required: false, default: 0 },
    custom_declaration_by: { type: String, required: false, default: "" },
    show: { type: undefined || Boolean, default: undefined },
    submitData: {
      type: Object as () => AddStockOutListSubmitData,
      required: true,
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<CommonFormType>(null);
    const addPreStockOutList = ref<CommonHtmlType>(null);
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const isHide = ref<boolean>(false);
    const testM = ref(props.id);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const filterOptions = ref<ShipmentAddStockOutListFilterOption>({
      sale_order_number: "",
      destination_warehouse_id: "",
      customer: "",
      priority: "",
      order_date_start: null,
      order_date_end: null,
      owner_id: "",
    });

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      custom_declaration_by: "1",
      destination_warehouse_id: "",
      sale_order_number: "",
      customer: "",
      owner_id: "",
      country: [] as string[],
      stock_out_ids: [] as number[],
      stock_out_list: [] as any[],
    });

    const rules = ref({
      // destination_warehouse_id: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
    });

    const options = ref({
      custom_declaration_by: [] as TaggingItem[],
      list: [] as any[],
      stock_out_list: [] as any[],
      cloneTableData: [] as any[],
      product_list: [] as ProductItem[],
      loadingProducts: true,
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
      customer: [] as TaggingItem[],
      isReset: false,
      warehouse: [] as TaggingItem[],
      country: [] as TaggingItem[],
      customerLoading: false,
    });

    watch(
      () => props.show,
      (newValue) => {
        if (newValue) {
          showModal(addPreStockOutList.value);
        } else if (!isHide.value) {
          hideModal(addPreStockOutList.value);
        }
      }
    );

    watch(
      () => props.custom_declaration_by,
      (newValue) => {
        formData.value.custom_declaration_by = newValue;
      }
    );

    watch(
      () => formData.value.custom_declaration_by,
      (newValue) => {
        if (props.custom_declaration_by != "") {
          formData.value.custom_declaration_by = props.custom_declaration_by;
        }
      }
    );

    watch(
      () => options.value.list,
      (newValue) => {
        setTableSelect();
      }
    );

    const userInfo = ref<User>(store.getters.currentUser);

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    const isSelectable = computed(() => {
      return (row) => {
        let flag = true;
        if (typeof row.order_all_paid !== "undefined") {
          if (row.order_all_paid === 0) {
            const selectCount = getSelectSameOrderItemQty.value(
              row.order_item_id
            );
            if (
              selectCount + row.target_quantity > row.to_ship_qty &&
              formData.value.stock_out_ids.indexOf(row.id) === -1
            ) {
              flag = false;
            }
          } else if (row.inventory_locked === 1) {
            flag = false;
          }
        }
        if (row.full_order == 1 && row.to_ship_qty == 0) {
          flag = false;
        }
        return flag;
      };
    });

    const setCellCalss = ({ row, columnIndex }) => {
      if (isSelectable.value(row) && columnIndex === 0) {
        if (getSelectOwner.value.indexOf(row.owner_id) > 0) {
          return "error";
        }
      }
      return "";
    };

    const getSelectOwner = computed(() => {
      let arr: number[] = [];
      formData.value.stock_out_list.map((item) => {
        arr.indexOf(item.owner_id) === -1 && arr.push(item.owner_id);
      });
      return arr;
    });

    const getSelectSameOrderItemQty = computed(() => {
      return (order_item_id) => {
        let count = 0;
        formData.value.stock_out_list.map((item) => {
          if (item.order_item_id === order_item_id) {
            count += item.target_quantity;
          }
        });
        return count;
      };
    });

    const getFilterList = () => {
      let resultList: any = options.value.stock_out_list;
      if (props.fulfillment_order_type == FulfillmentSource.Merchant) {
        resultList = resultList.filter((item) => {
          return item.quantity - item.delivery_quantity > 0;
        });
      }
      // if (
      //   props.submitData.shipment_type === "2" &&
      //   props.fulfillment_order_type != FulfillmentSource.Merchant
      // ) {
      //   resultList = resultList.filter((item) => {
      //     return (
      //       item.custom_declaration_by == formData.value.custom_declaration_by
      //     );
      //   });
      // }
      return resultList;
    };

    const handleSelectionChange = (selection) => {
      if (selection.length === 0 && !loading.value) {
        options.value.list.map((row) => {
          if (formData.value.stock_out_ids.indexOf(row.id) > -1) {
            formData.value.stock_out_ids.splice(
              formData.value.stock_out_ids.indexOf(row.id),
              1
            );
            formData.value.stock_out_list.splice(
              formData.value.stock_out_ids.indexOf(row.id),
              1
            );
          }
        });
      } else {
        selection.map((item) => {
          if (
            typeof item.order_all_paid !== "undefined" &&
            item.order_all_paid === 0 &&
            formData.value.stock_out_ids.indexOf(item.id) === -1
          ) {
            const selectCount = getSelectSameOrderItemQty.value(
              item.order_item_id
            );
            if (selectCount + item.target_quantity <= item.to_ship_qty) {
              formData.value.stock_out_ids.push(item.id);
              formData.value.stock_out_list.push(item);
            }
          } else if (formData.value.stock_out_ids.indexOf(item.id) === -1) {
            formData.value.stock_out_ids.push(item.id);
            formData.value.stock_out_list.push(item);
          }
        });
      }
      setTableSelect();
    };

    const handleSelectChange = (selection, row) => {
      if (formData.value.stock_out_ids.indexOf(row.id) > -1) {
        formData.value.stock_out_ids.splice(
          formData.value.stock_out_ids.indexOf(row.id),
          1
        );
        formData.value.stock_out_list.splice(
          formData.value.stock_out_ids.indexOf(row.id),
          1
        );
      }
    };

    const handleFilter = (val) => {
      filterOptions.value = val;
      getFormInfo();
    };

    const handleReset = () => {
      formData.value.stock_out_ids = [];
      formData.value.stock_out_list = [];
      options.value.stock_out_list = [];
      options.value.list = [];
      options.value.cloneTableData = [];
    };

    const getCustomerOptions = async (query: string) => {
      options.value.customerLoading = true;
      const { data } = await ApiBase.getCustomerSourceData({ name: query });
      options.value.customerLoading = false;
      if (data.code == 0) {
        options.value.customer = data.data;
      }
    };

    const debounceCustomerSearch = _.debounce(getCustomerOptions, 1000);

    const searchCustomerItems = (query: string) => {
      debounceCustomerSearch(query);
    };

    const getWarehouseData = async () => {
      const { data } = await ApiFulfillmentOrders.getWarehouseData({});
      if (data.code == 0) {
        const arr: TaggingItem[] = data.data.filter(
          (item) => item.value !== "Customer"
        );
        options.value.warehouse = arr;
      }
    };

    const getCountryData = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.country = data.data;
      }
    };

    const setTableSelect = () => {
      options.value.list.forEach((item: any) => {
        if (formData.value.stock_out_ids.indexOf(item.id) > -1) {
          multipleTableRef.value?.toggleRowSelection(item, true);
        } else {
          multipleTableRef.value?.toggleRowSelection(item, false);
        }
      });
    };

    const isDisabledSubmit = computed(() => {
      const stock_out_ids: any = formData.value.stock_out_ids,
        items: any = getFulfillmentOrderItems.value;
      if (stock_out_ids.length > 0) {
        // 商户采购
        if (props.fulfillment_order_type == FulfillmentSource.Merchant) {
          const fulfilled_items = items.filter((item, index) => {
            return (
              item.quantity <= 0 ||
              String(item.quantity).trim() == "" ||
              item.request_quantity > item.max_quantity
            );
          });
          if (fulfilled_items.length == 0) {
            return false;
          } else {
            return true;
          }
        } else {
          // 现货履约或物流
          let warehouse_id: any = [],
            stock_out_list: any[] = formData.value.stock_out_list;
          stock_out_list.forEach((item) => {
            if (warehouse_id.indexOf(item.warehouse_id) === -1) {
              warehouse_id.push(item.warehouse_id);
            }
          });
          if (
            warehouse_id.length > 1 &&
            props.submitData.shipment_type == "3"
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    });

    const getFulfillmentOrderItems = computed(() => {
      let items: any = [];
      options.value.cloneTableData.map((item) => {
        if (formData.value.stock_out_ids.indexOf(item.id) > -1) {
          const order =
            props.fulfillment_order_type == FulfillmentSource.Merchant
              ? {
                  id: item.id,
                  quantity: Number(item.request_quantity),
                }
              : {
                  id: item.id,
                  quantity: 0,
                };
          items.push(order);
        }
      });
      return items;
    });

    const showItemChangeTip = (fn) => {
      const shipment_type = props.submitData.shipment_type;
      Swal.fire({
        text:
          shipment_type == "2"
            ? t("shipments.merageTip")
            : t("shipments.merageCNTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          fn();
        }
      });
    };

    const isMergeShipment = async (fn) => {
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiShipments.checkMerge({
        shipment_type: props.submitData.shipment_type,
        service_provider: props.submitData.service_provider,
        tracking_number: props.submitData.tracking_no,
      });
      setSubmitAttribute(false);
      loading.value = false;
      if (data.code == 0) {
        if (data.data.exist === 1) {
          Swal.fire({
            text: t("shipments.itemChangeTip"),
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: t("common.yes"),
            cancelButtonText: t("common.no"),
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-light",
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              fn();
            }
          });
        } else {
          fn();
        }
      } else {
        showServerErrorMsg(data);
      }
    };

    const getSelectedAmount = computed(() => {
      let count = 0;
      formData.value.stock_out_list.map((item) => {
        count = Number(
          accounting.toFixed(count + Number(item.total_sale_price))
        );
      });
      return count;
    });

    const showExceedingAmountTip = (fn: () => void) => {
      if (
        getSelectedAmount.value >= 5000 &&
        props.submitData.shipment_type == "3"
      ) {
        Swal.fire({
          text: t("shipments.exceedingAmountTip", [getSelectedAmount.value]),
          icon: "warning",
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: t("common.confirm"),
          cancelButtonText: t("common.cancel"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-light",
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            fn();
          }
        });
      } else {
        fn();
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          if (getSelectOwner.value.length > 1) {
            Swal.fire({
              text: t("shipments.itemsSelectedSubmitError"),
              icon: "error",
              iconHtml: "!",
              buttonsStyling: false,
              confirmButtonText: t("common.okGotIt"),
              customClass: {
                confirmButton: "btn btn-danger",
              },
            }).then(async (result) => {
              if (result.isConfirmed) {
                // fn();
              }
            });
            return false;
          }
          if (props.fulfillment_order_id != 0) {
            addFulfillmentOrderShipment();
          } else {
            showExceedingAmountTip(() => {
              if (props.id === 0) {
                if (
                  props.submitData.shipment_type != "1" &&
                  props.submitData.tracking_no !== ""
                ) {
                  isMergeShipment(() => {
                    addShipment();
                  });
                } else {
                  addShipment();
                }
              } else {
                if (
                  props.submitData.shipment_type != "1" &&
                  props.shipment_invoice_id !== 0
                ) {
                  showItemChangeTip(() => {
                    addShipmentItem();
                  });
                } else {
                  addShipmentItem();
                }
              }
            });
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const addFulfillmentOrderShipment = async () => {
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiFulfillmentOrders.addFulfillmentOrderShipment({
        id: props.fulfillment_order_id,
        items: getFulfillmentOrderItems.value,
        custom_declaration_by: formData.value.custom_declaration_by,
        shipment_type: props.submitData.shipment_type,
        service_provider: props.submitData.service_provider,
        tracking_no: props.submitData.tracking_no,
        destination: props.submitData.destination_warehouse_id,
        pick_up_by: props.submitData.pick_up_by,
      });

      setSubmitAttribute(false);
      loading.value = false;
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          hideModal(addPreStockOutList.value);
          emit("update-list");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const addShipment = async () => {
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiShipments.addShipment({
        ...formData.value,
        ...props.submitData,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          resetForm(true);
          getFormInfo();
          emit("update-list");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const addShipmentItem = async () => {
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiShipments.addShipmentItem({
        stock_ids: formData.value.stock_out_ids,
        id: props.id,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          resetForm();
          getFormInfo();
          emit("update-list");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const getFulfillmentOrderData = async () => {
      if (props.fulfillment_order_type != FulfillmentSource.Merchant) return;
      const { data } = await ApiFulfillmentOrders.getFulfillmentOrderData({
        id: props.fulfillment_order_id,
      });
      if (data.code == 0) {
        options.value.stock_out_list = data.data.fulfilled_items;
        options.value.cloneTableData = getFilterList();
        options.value.cloneTableData.forEach((item) => {
          item.request_quantity = item.quantity - item.delivery_quantity;
          item.max_quantity = item.quantity - item.delivery_quantity;
        });
        options.value.list = getFilterList();
        let ids: any = [],
          customerMobile: any[] = [],
          customer: any[] = [];
        data.data.fulfilled_items.forEach((item) => {
          // ids.push(item.sku_identifier);
          if (customerMobile.indexOf(item.mobile) == -1) {
            customerMobile.push(item.mobile);
            customer.push({
              label: item.customer_name,
              value: item.mobile,
            });
          }
        });
        // options.value.customer = customer;
        // if (ids.length != 0) {
        //   getProductsData({
        //     sku_id: ids,
        //     merchant_id: 0,
        //   });
        // }
      }
    };

    const getPreStockOutList = async () => {
      if (props.fulfillment_order_type == FulfillmentSource.Merchant) return;
      if (
        formData.value.destination_warehouse_id === "" ||
        formData.value.country.length === 0
      )
        return;
      const params =
        props.fulfillment_order_type == FulfillmentSource.PreStock
          ? {
              fulfillment_order_number: props.fulfillment_order_number,
            }
          : {
              sale_order_number: formData.value.sale_order_number || "",
              warehouse_id: formData.value.destination_warehouse_id || "",
              country: formData.value.country,
              customer_id: formData.value.customer || "",
              shipment_type: props.submitData.shipment_type,
              owner_id: formData.value.owner_id,
            };
      const { data } = await ApiStock.getPreStockOutList(params);
      if (data.code == 0) {
        options.value.stock_out_list = data.data;
        options.value.list = getFilterList();
        options.value.cloneTableData = getFilterList();
        let ids: any = [],
          customerMobile: any[] = [],
          customer: any[] = [];
        data.data.forEach((item) => {
          // ids.push(item.sku_identifier);
          if (customerMobile.indexOf(item.mobile) == -1) {
            customerMobile.push(item.mobile);
            customer.push({
              label: item.customer_name,
              value: item.mobile,
            });
          }
        });
        // options.value.customer = customer;
        // if (ids.length != 0) {
        //   getProductsData({
        //     sku_id: ids,
        //     merchant_id: 0,
        //   });
        // }
      }
    };

    const getShowInfo = () => {
      if (props.fulfillment_order_type == FulfillmentSource.Merchant) {
        getFulfillmentOrderData();
      } else {
        getPreStockOutList();
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["fulfillment_delivery_type"],
      });
      if (data.code == 0) {
        options.value.custom_declaration_by =
          data.data.fulfillment_delivery_type.items;
      }
    };

    const getFormInfo = async () => {
      loading.value = true;
      multipleTableRef.value?.clearSelection();
      Promise.all([getFulfillmentOrderData(), getPreStockOutList()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const debounceProductSearch = _.debounce(getFormInfo, 1000);

    const updatePreStockOutList = (e) => {
      debounceProductSearch();
    };

    const resetForm = (flag = false) => {
      formRef.value?.resetFields();
      options.value.list = [];
      options.value.stock_out_list = [];
      options.value.cloneTableData = [];
      formData.value.stock_out_ids = [];
      formData.value.stock_out_list = [];
      if (!flag) {
        emit("reset-form");
        formData.value.country = [];
        formData.value.destination_warehouse_id = "";
        formData.value.customer = "";
        formData.value.sale_order_number = "";
        formData.value.owner_id = String(
          userInfo.value.settlement_default_owner
        );
        filterOptions.value.customer = "";
        filterOptions.value.priority = "";
        filterOptions.value.sale_order_number = "";
        filterOptions.value.destination_warehouse_id = "";
        filterOptions.value.order_date_start = null;
        filterOptions.value.order_date_end = null;
        filterOptions.value.owner_id = String(
          userInfo.value.settlement_default_owner
        );
      }
    };

    const typeChange = async (val) => {
      multipleTableRef.value?.clearSelection();
      options.value.list = await getFilterList();
      // getFormInfo();
    };

    const handleBack = () => {
      hideModal(addPreStockOutList.value);
    };

    const init = () => {
      setTimeout(() => {
        if (props.submitData.country) {
          formData.value.country = [props.submitData.country];
        }
        if (userInfo.value) {
          formData.value.owner_id = String(
            userInfo.value.settlement_default_owner
          );
          filterOptions.value.owner_id = String(
            userInfo.value.settlement_default_owner
          );
        }
        getFormInfo();
      }, 0);
    };

    onMounted(() => {
      modalShowListener(addPreStockOutList.value, () => {
        isHide.value = false;
        init();
      });
      modalHideListener(addPreStockOutList.value, () => {
        isHide.value = true;
        resetForm();
      });
      getWarehouseData();
      getCountryData();
      getTaggingData();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      store,
      formatDate,
      formatDateTime,
      props,
      addPreStockOutList,
      loading,
      formData,
      multipleTableRef,
      submitButton,
      formRef,
      options,
      rules,
      FulfillmentSource,
      getSelectedAmount,
      showExceedingAmountTip,
      isSelectable,
      setCellCalss,
      getSelectOwner,
      updatePreStockOutList,
      searchCustomerItems,
      submit,
      resetForm,
      handleSelectionChange,
      handleSelectChange,
      handleFilter,
      handleReset,
      setTableSelect,
      handleBack,
      getFilterList,
      typeChange,
      isDisabledSubmit,
    };
  },
});
